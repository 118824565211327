import React, { Component } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

//layout
import ModalStoryRMS from "../../components/RMSStory";
import Layout from "../../components/Layout";
import RMSNetwork from "../../components/RMSNetwork";
import VideoHeaderRMS from "../../components/VideoHeaderRMS";

// assets
import whoWeAre from "../../assets/images/logo-square.svg";
import whyChooseUs from "../../assets/images/wcu.jpeg";
import whatsapp from "../../assets/images/socmed/logo-whatsapp.png";

// icons
import { FaLongArrowAltRight } from "react-icons/fa";

//static data
import { _whyChooseUs } from "../../static/why_choose_us";
import { _whyChooseUs_id } from "../../static/why_choose_us";

//translate
import { withTranslation } from "react-i18next";
import GlobalVariable from "../../components/GlobalVariable";
import { Helmet } from "react-helmet-async";

class Homepage extends Component {
  render() {
    const bahasa = GlobalVariable.lang;
    let why = "";

    if (bahasa === "id") {
      why = _whyChooseUs_id;
    } else {
      why = _whyChooseUs;
    }
    return (
      <>
        <Helmet>
          <title>RMS - Rukun Mitra Sejati | Distributor FMCG</title>
          <meta
            name="description"
            content="RMS is FMCG Distributor Company Handling FMCG Supply Chain. Distributor Nestle, Unilever, Unicharm, Perfetti Van Melle, and other great brands in Indonesia"
          />
          <meta
            property="og:title"
            content="RMS - Rukun Mitra Sejati | FMCG Distributor"
          />
          <meta
            property="og:description"
            content="RMS - Rukun Mitra Sejati is a leading FMCG distributor. Explore our wide range of products and learn more about our services."
          />
          <meta property="og:url" content="http://rms.co.id" />
        </Helmet>
        <Layout>
          <VideoHeaderRMS />
          <main>
            <section href="#about" className="contact-parent">
              <Button
                className="mt-4 text-white px-4 py-2 nav-link"
                variant="link"
                style={{ backgroundColor: "#22c15e", borderRadius: "20px" }}
                href="https://wa.me/628113697011"
              >
                <img src={whatsapp} alt="" style={{ marginTop: "-3px" }} />{" "}
                {this.props.t("btn_contact_us")}
              </Button>
            </section>
            <section style={{ marginBottom: "3rem" }} id="about">
              <Container className="px-5">
                <Row>
                  <Col
                    md={6}
                    lg={{ span: 5, offset: 1 }}
                    className="text-center align-self-center my-4 wow animate__animated animate__fadeInLeft"
                    data-wow-duration="1s"
                  >
                    <img src={whoWeAre} className="img-fluid" alt="" />
                  </Col>
                  <Col
                    md={6}
                    lg={5}
                    className="align-self-center my-4 wow animate__animated animate__fadeInRight"
                    data-wow-duration="1s"
                  >
                    <h2 className="fw-bold">
                      <span className="bl-2" />
                      {this.props.t("title_who_we_are")}
                    </h2>
                    <p className="mt-4">
                      <span className="fw-bold justified-paragraph">
                        {" "}
                        {this.props.t("detail_who_we_are_bold")}
                      </span>
                    </p>
                    <p className="justified-paragraph">
                      <span>{this.props.t("detail_who_we_are")}</span>{" "}
                      <span className="fw-bold">
                        {this.props.t("join_who_we_are")}
                      </span>
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>

            <ModalStoryRMS />

            <RMSNetwork />

            <Container className="overview-parent">
              <Card className="overview">
                <Card.Body>
                  <Row>
                    <Col
                      md={10}
                      lg={{ span: 6, offset: 1 }}
                      className="wow animate__animated animate__fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <h2 className="fw-bold my-4">
                        <span className="bl-2" />
                        {this.props.t("title_overview")}
                      </h2>
                      <Row className="mt-5">
                        <Col xs={4}>
                          <h1 className="mb-0 fw-bolder">
                            {/* <CountUp end={70} duration={2} delay={0.8} /> + */}
                            100 +
                          </h1>
                          <p className="fw-normal">
                            {this.props.t("branch_overview")}
                          </p>
                        </Col>
                        <Col xs={6}>
                          <h1 className="mb-0 fw-bolder">
                            {/* <CountUp end={2.200} duration={2} delay={0.8} />+ */}
                            2.500 +
                          </h1>
                          <p className="fw-normal">
                            {this.props.t("retail_store_overview")}
                          </p>
                        </Col>
                      </Row>
                      <p className="text-muted mt-4">
                        {this.props.t("date_overview")}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>

            <section className="text-white bg-mission">
              <Container className="mt-5" style={{ paddingTop: "15rem" }}>
                <Row>
                  <Col
                    md={6}
                    lg={5}
                    className="wow animate__animated animate__fadeInLeft"
                    data-wow-duration="1s"
                  >
                    <h2 className="fw-bold">
                      <span className="bl-3" />
                      {this.props.t("title_why_choose_us")}
                    </h2>
                    <p className="mt-4">
                      <span>{this.props.t("desc_why_choose_us")}</span>
                    </p>
                  </Col>
                  <Col
                    className="text-center align-center wow animate__animated animate__fadeInRight"
                    data-wow-duration="1s"
                    md={4}
                    lg={{ span: 5, offset: 1 }}
                  >
                    <img src={whyChooseUs} className="img-choose-us" alt="" />
                  </Col>
                </Row>
                <div style={{ marginTop: "2rem" }}>
                  <div className="hori-timeline" dir="ltr">
                    <div className="list-inline events">
                      <Row>
                        {why.map((data, i) => (
                          <Col
                          key={i}
                            lg={3}
                            sm={3}
                            className="list-inline-item event-list wow animate__animated animate__fadeIn"
                            data-wow-delay={`${(i * 2) / 10}s`}
                          >
                            <div>
                              <img
                                src={data.img}
                                alt=""
                                className="icon-choose-us"
                              />
                              <h5>{data.title}</h5>
                              <p className="text-white">{data.content}</p>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </div>
              </Container>
            </section>

            <section className="py-5 bg-alabaster collaborate-parent">
              <Container className="py-5">
                <Card className="collaborate">
                  <Card.Body>
                    <Row>
                      <Col
                        className="wow animate__animated animate__fadeIn text-center"
                        data-wow-delay="0.5s"
                      >
                        <h1 className="fw-bold my-4">Rukun Mitra Sejati</h1>

                        <h6 className="text-muted mt-4">
                          {this.props.t("desc_collaborate")}
                        </h6>
                        <br />
                        <Link to={"/contact-us"} className="nav-link">
                          <Button
                            className="text-white btn-collaborate"
                            style={{
                              backgroundColor: "#0092e7",
                              borderRadius: "20px",
                              alignItems: "center",
                            }}
                          >
                            {this.props.t("btn_collaborate")}{" "}
                            <FaLongArrowAltRight
                              className="ms-3"
                              style={{ marginTop: "-3px" }}
                            />
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
            </section>
          </main>
        </Layout>
      </>
    );
  }
}

export default withTranslation()(Homepage);
