import React, { useState } from "react";
import { BaseModal } from "./base/BaseModal";
import { useTranslation } from "react-i18next";
import ImageCarousel from "./base/BaseCarousel";
import {
  highlightContentEn,
  highlightContentId,
} from "../static/highlight_news";
import GlobalVariable from "./GlobalVariable";

const HighlightNewsDetail = () => {
  const isEn = GlobalVariable.lang === "en";
  return (
    <>
      <div className="row g-0 px-2">
        <div className="col-lg-6">
          <ImageCarousel images={highlightContentEn.images} />
        </div>
        <div className="col-lg-6 ps-4">
          <h3 className="fw-bold">
            {isEn ? highlightContentEn.title : highlightContentId.title}
          </h3>
          <h4 className="text-warning fs-5 mb-3">
            {isEn ? highlightContentEn.location : highlightContentId.location}
          </h4>
          <p className="display-linebreak">
            {isEn ? highlightContentEn.content : highlightContentId.content}
          </p>
          {highlightContentId.otherSources.map((source) => (
            <>
              <a href={source.link} className="small fst-italic"  target="_blank" rel="noreferrer">{source.name}</a>
              <br />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export const HighlightNews = () => {
  const isEn = GlobalVariable.lang === "en";
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="card p-4 rounded-3 overflow-hidden border-0 dark:focus:ring-1 dark:focus:ring-gray-600 shadow-sm">
        <div className="row g-0">
          <div className="col-md-6">
            <div className="ratio ratio-16x9">
              <iframe
                className="rounded"
                width="auto"
                height="auto"
                src="https://www.youtube.com/embed/ij29EnBaBjc"
                title="RMS x Banser: Ansor Stokis"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-start py-4 px-4">
            <div className="flex-grow-1 ms-md-3">
              <h2 className="h2 fw-bold mb-3">
                {isEn ? highlightContentEn.title : highlightContentId.title}
              </h2>
              <p className="opacity-75">
                {isEn ? highlightContentEn.date : highlightContentId.date}
              </p>
              <h3 className="text-warning fs-5 mb-3">
                {isEn
                  ? highlightContentEn.location
                  : highlightContentId.location}
              </h3>
              <p className="text-muted mb-3">
                {isEn
                  ? highlightContentEn.description
                  : highlightContentId.description}
              </p>
              <button
                onClick={handleShow}
                type="button"
                className="btn p-0 d-flex align-items-center text-info"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                // className=""
              >
                {t("nc_2024.btn")}
                <i className="fa fa-chevron-right mx-2"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <BaseModal
        show={show}
        handleClose={handleClose}
        content={<HighlightNewsDetail />}
      />
    </>
  );
};
