export const highlightContentId = {
  title: "Program Ansor Stokis sebagai Kolaborasi antara Ansor dan RMS",
  description:
    "Gerakan Pemuda (GP) Ansor menggandeng PT Rukun Mitra Sejati (RMS) untuk mendukung sektor Usaha Mikro Kecil dan Menengah (UMKM) agar terus menjadi penopang ekonomi nasional.",
  date: "08 Desember 2024",
  location: "Thamrin 10, Jakarta",
  content: `
  Gerakan Pemuda (GP) Ansor bekerja sama dengan PT Rukun Mitra Sejati (RMS) untuk mendukung perkembangan sektor Usaha Mikro Kecil dan Menengah (UMKM) agar tetap menjadi tulang punggung perekonomian nasional. Kerja sama ini sejalan dengan target pemerintah di bawah Prabowo Subianto untuk mencapai pertumbuhan ekonomi sebesar 8 persen \n
  “Kami menggandeng RMS yang merupakan mitra strategis Ansor dalam mengembangkan UMKM, khususnya para kader Ansor pelaku UMKM, termasuk mengintegrasikan dengan cita-cita pemerintah yakni target pertumbuhan ekonomi 8 persen,” ujar Ketua Umum Pimpinan Pusat GP Ansor, Addin Jauharudin di Jakarta, Sabtu (7/12/2024). \n
  Kegiatan ini bertujuan mempercepat perkembangan bisnis UMKM agar lebih kuat secara kelembagaan serta terus memberikan kontribusi positif, baik dalam meningkatkan ekonomi masyarakat maupun menyerap tenaga kerja.
  `,
  thumnail:
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/xvxtlekjkioeup51a0t2",
  images: [
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/v5hauirkkfp06j271fes",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/smbdwtcysesg4dtpmslv",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/l5ue2gcyjonclaw4oteb",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/onjh7c7na3npkmnvwquf",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/kgu8ctmgfruaqayti4pl",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/w6hhzijf9axo93gwmqw6",
  ],
  otherSources: [
    {
      name: "Media Indonesia",
      link: "https://mediaindonesia.com/ekonomi/724251/kolaborasi-untuk-perkuat-umkm-gp-ansor-akan-rilis-ansor-stokis",
    },
    {
      name: "Okezone",
      link: "https://nasional.okezone.com/read/2024/12/07/337/3093371/dukung-program-prabowo-gp-ansor-launching-ansor-stokis-di-cfd-sudirman-thamrin",
    },
    {
      name: "Detik",
      link: "https://www.detik.com/hikmah/khazanah/d-7675650/gp-ansor-luncurkan-ansor-stokis-dukung-penguatan-umkm",
    },
    {
      name: "Sindo News",
      link: "https://nasional.sindonews.com/read/1499527/15/perkuat-umkm-gp-ansor-rms-launching-ansor-stokis-di-cfd-besok-1733544734",
    },
  ],
};

export const highlightContentEn = {
  title: "Ansor Stokis Program as a Collaboration between Ansor and RMS",
  description:
    "The Ansor Youth Movement (GP) is collaborating with PT Rukun Mitra Sejati (RMS) to support the Micro, Small and Medium Enterprises (MSMEs) sector so that it continues to support the national economy.",
  date: "08 December 2024",
  location: "Thamrin 10, 2024",
  content: `
  The Youth Movement (GP) Ansor has partnered with PT Rukun Mitra Sejati (RMS) to support the development of the Micro, Small, and Medium Enterprises (MSME) sector, ensuring it remains a backbone of the national economy. This collaboration aligns with the government’s goal under Prabowo Subianto to achieve 8 percent economic growth. \n
  "We are collaborating with RMS, which is Ansor's strategic partner in developing MSMEs, especially Ansor cadres who are MSME actors, including integrating with the government's ideals, namely the 8 percent economic growth target," said the General Chairperson of the GP Ansor Central Leadership, Addin Jauharudin in Jakarta, Saturday (7/12/2024). \n
  This initiative aims to accelerate MSME business growth, strengthening its institutional capacity and enabling it to continue contributing positively to economic development and job creation.
  `,
  images: [
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/v5hauirkkfp06j271fes",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/smbdwtcysesg4dtpmslv",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/l5ue2gcyjonclaw4oteb",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/onjh7c7na3npkmnvwquf",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/kgu8ctmgfruaqayti4pl",
    "https://res.cloudinary.com/dcmya61ry/image/upload/f_auto,q_auto/v1/highlight/w6hhzijf9axo93gwmqw6",
  ],
  source:
    "https://mediaindonesia.com/ekonomi/724251/kolaborasi-untuk-perkuat-umkm-gp-ansor-akan-rilis-ansor-stokis",
  otherSources: [
    {
      name: "Media Indonesia",
      link: "https://mediaindonesia.com/ekonomi/724251/kolaborasi-untuk-perkuat-umkm-gp-ansor-akan-rilis-ansor-stokis",
    },
    {
      name: "Okezone",
      link: "https://nasional.okezone.com/read/2024/12/07/337/3093371/dukung-program-prabowo-gp-ansor-launching-ansor-stokis-di-cfd-sudirman-thamrin",
    },
    {
      name: "Detik",
      link: "https://www.detik.com/hikmah/khazanah/d-7675650/gp-ansor-luncurkan-ansor-stokis-dukung-penguatan-umkm",
    },
    {
      name: "Sindo News",
      link: "https://nasional.sindonews.com/read/1499527/15/perkuat-umkm-gp-ansor-rms-launching-ansor-stokis-di-cfd-besok-1733544734",
    },
  ],
};
